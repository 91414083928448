







import { Component, Vue, Prop } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router';
import { ArrowLeftIcon } from 'vue-feather-icons';

@Component({
  components: {
    ArrowLeftIcon
  }
})
export default class GoBack extends Vue {
  @Prop() label!: string;
  @Prop() path!: string;
  @Prop() query!: Dictionary<string>;
  @Prop({ default: false, type: Boolean }) hasIcon!: boolean;

  public goBack() {
    this.$router.push({ path: this.path, query: this.query });
  }
}
