































































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import { InfoIcon, CalendarIcon } from 'vue-feather-icons';
import store from '@/store';
import moment from 'moment';
import { cs } from 'vuejs-datepicker/dist/locale';
import { GtagMixin } from '@/mixins/googleAnalytics/gtag.mixin';

@Component({
  components: {
    Datepicker: () => import('vuejs-datepicker'),
    InfoIcon,
    CalendarIcon
  }
})
export default class VanReservationDateTooltip extends Mixins(GtagMixin) {
  $refs!: {
    dpStart: (Vue & { isOpen: boolean; close: () => void; showCalendar: () => void }) | undefined;
    dpEnd: (Vue & { isOpen: boolean; close: () => void; showCalendar: () => void }) | undefined;
  };

  get startDate() {
    return store.state.reservationStartDate;
  }

  get startDayMonth() {
    return moment(this.startDate).locale('cs').format('D. MMMM');
  }

  get endDate() {
    return store.state.reservationEndDate;
  }

  get endDayMonth() {
    return moment(this.endDate).locale('cs').format('D. MMMM');
  }

  private setStartDate(date: string) {
    store.dispatch('setReservationStartDate', date);
  }

  private setEndDate(date: string) {
    store.dispatch('setReservationEndDate', date);
  }

  get disabledDatesStart() {
    return store.state.reservationStartDisableDates;
  }

  get disabledDatesEnd() {
    return store.state.reservationEndDisableDates;
  }

  get datepickerLocale() {
    return cs;
  }

  get startDateOpened() {
    if (!this.$refs.dpStart) {
      return false;
    }
    return this.$refs.dpStart.isOpen;
  }

  @Watch('startDate', { immediate: false })
  onChangeStartDate() {
    this.$refs.dpStart?.close();
    this.$refs.dpEnd?.close();
  }

  @Watch('endDate', { immediate: false })
  onChangeEndDate() {
    this.$refs.dpStart?.close();
    this.$refs.dpEnd?.close();
  }

  private toggleStartCalendar() {
    const dpStart = this.$refs.dpStart;
    const dpEnd = this.$refs.dpEnd;
    if (dpStart) {
      if (dpStart.isOpen) {
        dpStart.close();
      } else {
        dpStart.showCalendar();
        if (dpEnd && dpEnd.isOpen) {
          dpEnd.close();
        }
      }
    }
  }

  private closeStartCalendar() {
    const dpStart = this.$refs.dpStart;
    if (dpStart && dpStart.isOpen) {
      dpStart.close();
    }
  }

  private toggleEndCalendar() {
    const dpStart = this.$refs.dpStart;
    const dpEnd = this.$refs.dpEnd;
    if (dpEnd) {
      if (dpEnd.isOpen) {
        dpEnd.close();
      } else {
        dpEnd.showCalendar();
        if (dpStart && dpStart.isOpen) {
          dpStart.close();
        }
      }
    }
  }

  private closeEndCalendar() {
    const dpEnd = this.$refs.dpEnd;
    if (dpEnd && dpEnd.isOpen) {
      dpEnd.close();
    }
  }
}
