























import { Component, Vue, Prop } from 'vue-property-decorator';
import CheckboxDropdown from '@/components/ui/form/CheckboxDropdown.vue';
import Tooltip from '@/components/tooltip.vue';
import VanDepositMenu from '@/components/vans/vanDepositMenu.vue';
import TreeSelect from '../ui/form/TreeSelect.vue';
import VanSort from './vanSort.vue';
import { FilterOption } from '@/store/types';
import { CarSortOption } from '@/models/carSort';

@Component({
  components: { CheckboxDropdown, Tooltip, VanDepositInfo: VanDepositMenu, TreeSelect, VanSort }
})
export default class VanFilter extends Vue {
  @Prop() readonly locations!: FilterOption[];
  @Prop() readonly filteredLocations!: FilterOption[];
  @Prop() readonly onFilteredLocationsChange!: (locations: FilterOption[]) => void;
  @Prop() readonly vans!: FilterOption[];
  @Prop() readonly filteredVans!: FilterOption[];
  @Prop() readonly onFilteredVansChange!: (vans: FilterOption[]) => void;
  @Prop() readonly availableVansCount!: number;
  @Prop() readonly selectedSortOption!: CarSortOption;
  @Prop() readonly allSortOptions!: CarSortOption[];
  @Prop() readonly onSortOptionChange!: (option: CarSortOption) => void;

  get freeVanDeclension(): string {
    if (this.availableVansCount === 1) {
      return 'volná';
    } else if (this.availableVansCount === 2 || this.availableVansCount === 3 || this.availableVansCount === 4) {
      return 'volné';
    } else {
      return 'volných';
    }
  }
}
