export default function getDayString(days: number) {
  switch (true) {
    case days === 0 || days > 4:
      return 'dní';
    case days === 1:
      return 'den';
    case days === 2 || days === 3 || days === 4:
      return 'dny';
    default:
      return 'den';
  }
}
