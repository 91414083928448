export const carSortOptions: CarSortOption[] = [
  {
    name: 'doporučené',
    prop: null,
    order: null
  },
  {
    name: 'cena',
    prop: 'price',
    order: 1
  },
  {
    name: 'cena',
    prop: 'price',
    order: -1
  }
];
export const defaultCarSortOption = carSortOptions[0];
export interface CarSortOption {
  name: string;
  prop: string | null;
  order: number | null;
}
