





























import { Component, Vue, Prop } from 'vue-property-decorator';
import { ChevronUpIcon, ChevronDownIcon } from 'vue-feather-icons';
import { CarParameter } from '@/store/types';
import { getParameterIconSrc } from '@/components/vans/utils/carParameters';
import Tooltip from '@/components/tooltip.vue';

// height of one row with parameter info (in pixels)
const ROW_HEIGHT = 40;

@Component({
  components: { Tooltip, ChevronUpIcon, ChevronDownIcon }
})
export default class VanDetailParameters extends Vue {
  @Prop() readonly parameters!: CarParameter[];

  private open = false;

  get parameterRowHeight() {
    return ROW_HEIGHT + 'px';
  }

  // When closed, returns 0px height. When open, returns computed height for transition animation.
  get parameterRowsHeight() {
    if (this.open) {
      return this.parameters.length * ROW_HEIGHT + 'px';
    }
    return 2.7 * ROW_HEIGHT + 'px';
  }

  private parameterIconSrc(parameter: CarParameter) {
    if (this.open) {
      return getParameterIconSrc(parameter.key, '#767676', 18, 18);
    }
    return getParameterIconSrc(parameter.key, '#e8e8e8', 18, 18);
  }
}
