





























import { Component, Prop, Mixins } from 'vue-property-decorator';
import GoBack from '@/components/goBack.vue';
import Tooltip from '@/components/tooltip.vue';
import Button from '@/components/ui/Button.vue';
import VanDepositMenu from '@/components/vans/vanDepositMenu.vue';
import { ArrowRightIcon } from 'vue-feather-icons';
import getDayString from '@/utils/getDayString';
import { CarsDepositInfo } from '@/models/carsDeposit';
import { GtagMixin } from '@/mixins/googleAnalytics/gtag.mixin';

@Component({
  components: { GoBack, ArrowRightIcon, Tooltip, VanDepositMenu, Button }
})
export default class VanReservationInfo extends Mixins(GtagMixin) {
  @Prop() readonly overallPrice!: number;
  @Prop() readonly daysCount!: number;
  @Prop() readonly isAvailable!: boolean;
  @Prop() readonly deposits!: CarsDepositInfo[];
  @Prop() readonly selectedDeposit!: CarsDepositInfo;
  @Prop() readonly onSelectedDepositChange!: (deposit: CarsDepositInfo) => void;

  get carName(): string {
    return this.$store.state.selectedCar.name;
  }
  get daysCountString() {
    return `${this.daysCount}\xa0${getDayString(this.daysCount)}`;
  }

  private handleButtonClick() {
    this.triggerGtagEvent('reservation-add-car-to-cart.button', 'click', {
      car: this.carName
    });
    this.$emit('reserve');
  }
}
