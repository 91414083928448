























import { Component, Vue, Prop } from 'vue-property-decorator';
import CheckboxField from '@/components/ui/form/CheckboxField.vue';
import { ChevronUpIcon, ChevronDownIcon } from 'vue-feather-icons';

@Component({
  components: { CheckboxField, ChevronUpIcon, ChevronDownIcon }
})
export default class CheckboxDropdown extends Vue {
  @Prop({ required: true, type: String }) readonly name!: string[];
  @Prop({ required: true, type: Array }) readonly allValues!: string[];
  @Prop({ required: true, type: Array }) readonly value!: string[];

  private open = false;

  get title() {
    if (this.value.length === 0) {
      return this.name;
    }
    return this.value.join(', ');
  }

  public handleInput(_value: string, selected: boolean) {
    if (selected) {
      this.$emit('input', this.value.concat([_value]));
    } else {
      this.$emit(
        'input',
        this.value.filter((v) => v !== _value)
      );
    }
  }

  private handleMouseClick(ev: MouseEvent) {
    if (this.open && !this.$el.contains(ev.target as Node | null)) {
      this.open = false;
    }
  }

  private initMouseClickHandler() {
    document.removeEventListener('click', this.handleMouseClick);
    document.addEventListener('click', this.handleMouseClick);
  }

  private destroyMouseClickHandler() {
    this.open = false;
    document.removeEventListener('click', this.handleMouseClick);
  }

  mounted() {
    this.initMouseClickHandler();
  }

  activated() {
    this.initMouseClickHandler();
  }

  destroyed() {
    this.destroyMouseClickHandler();
  }

  deactivated() {
    this.destroyMouseClickHandler();
  }
}
