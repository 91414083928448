


















































import { Component, Vue, Watch } from 'vue-property-decorator';
import VanSelection from '@/components/vans/vanSelection.vue';
import { NavigationGuardNext, Route } from 'vue-router';
import store from '@/store';
import { metaInfo } from '@/utils/seo/metaInfo';
import { buildUrl } from '@/utils/url';
import VanDetail from '@/components/vans/vanDetail.vue';
import { CarFilterInput } from '@/models/carFilterInput';
import { RENTAL } from '@/models/carType';
import moment from 'moment';
import { Car, CarAvailability, FilterOption, VehicleType } from '@/store/types';
import carService from '../services/cars.service';
import { CarsDepositInfo } from '@/models/carsDeposit';
import { createVanSelectionRoute } from '@/utils/routing';
import { ignoreNavigationDuplicated } from '@/router/utils';
import { LatLngExpression } from 'leaflet';
import { getParkingPositionsFromCars } from '@/components/map/locationParser';
import { VanSelectionRoute } from '@/router/routes';
import ClientOnly from 'vue-client-only';
import { CarSortOption, carSortOptions, defaultCarSortOption } from '@/models/carSort';
import cloneDeep from 'lodash.clonedeep';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';

const cityMetas = [
  {
    name: 'Praha',
    slug: 'praha',
    metaDescription:
      'Online půjčovna dodávek Praha. Vyberte si termín a dodávku, registrujte se, zaplaťte a vyrazte! Všechno vyřídíte on-line 24/7. Lokality Holešovice, Letňany, Černý most, Strahov, Zličín, Vršovice a Hostivař.',
    metaTitle: 'Půjčovna dodávek Praha - Volné dodávky'
  },
  {
    name: 'Plzeň',
    slug: 'plzen',
    metaDescription:
      'Online půjčovna dodávek Plzeň. Vyberte si termín a dodávku, registrujte se, zaplaťte a vyrazte! Všechno vyřídíte on-line 24/7.',
    metaTitle: 'Půjčovna dodávek Plzeň - Volné dodávky'
  },
  {
    name: 'Zlín',
    slug: 'zlin',
    metaDescription:
      'Online půjčovna dodávek Zlín. Vyberte si termín a dodávku, registrujte se, zaplaťte a vyrazte! Všechno vyřídíte on-line 24/7.',
    metaTitle: 'Půjčovna dodávek Zlín - Volné dodávky'
  }
];

@Component({
  components: {
    VanSelection,
    VanDetail,
    MapBox: () => (process.client ? import('@/components/map/mapBox.vue') : import('vue-client-only')),
    ClientOnly
  },
  beforeRouteLeave(_to: Route, _from: Route, next: NavigationGuardNext<Vans>) {
    store.dispatch('setCityUrl', null);
    store.dispatch('setCarTypeUrl', null);
    next();
  },
  metaInfo() {
    const cityMeta = cityMetas.find((cm) => cm.slug === this.$route.params.city);
    if (cityMeta) {
      return metaInfo({
        title: cityMeta.metaTitle,
        description: cityMeta.metaDescription,
        url: buildUrl(`/dodavky/${cityMeta.slug}`)
      });
    }
    return metaInfo({
      title: 'Najděte volné dodávky k půjčení | Kakadoo',
      url: buildUrl('/dodavky'),
      description: 'Ceny dodávek již od 590 Kč/den. Žádnými skrytými poplatky vás nepřekvapíme.'
    });
  }
})
export default class Vans extends Vue {
  public loading = true;
  public carTypePathSlugs: string[] = [];
  public citySlugs: string[] = [];
  public daysCount = 0;
  public failed = false;
  public filteredLocations: FilterOption[] = [];
  public filteredVans: FilterOption[] = [];
  public allLocationFilters: FilterOption[] = [];
  public allVanTypes: FilterOption[] = [];
  public allSortOptions: CarSortOption[] = carSortOptions;
  public selectedSortOption: CarSortOption = defaultCarSortOption;
  // TODO - hardcoded - get dynamic data from BE
  private deposits: CarsDepositInfo[] = [
    {
      id: 1,
      value: 5000,
      participationPercentage: 5,
      participationMin: 5000,
      vanPriceMin: 430
    },
    {
      id: 2,
      value: 2000,
      participationPercentage: 2,
      participationMin: 2000,
      vanPriceMin: 520
    }
  ];
  private selectedDeposits: { id: string; deposit: CarsDepositInfo }[] = [];
  private mapShowed = false;
  public displayMode = 1;
  private primaryParam = '';
  public focusMapTo: LatLngExpression | null = null;
  public highlightedCar: string | null = null;

  handleChangeMapInViewport(visible: boolean) {
    if (visible && !this.mapShowed) {
      this.mapShowed = true;
    }
  }

  get carsWithAvailability() {
    return store.state.carsWithAvailability;
  }

  get carSelectedAvailability() {
    const carSelectedId = this.carSelected?._id;
    if (carSelectedId) {
      return this.carsWithAvailability.find((cwa) => cwa.car._id === carSelectedId)?.isAvailable;
    }
  }

  get carSelected(): Car | null {
    return store.state.selectedCar;
  }

  get filteredCars() {
    return this.carsWithAvailability.filter((car) => car.isAvailable).map((cwa) => cwa.car);
  }

  get availableCarsCount() {
    return this.carsWithAvailability.filter((car) => car.isAvailable).length;
  }
  get filteredVanTypes(): VehicleType[] {
    return this.filteredVans.map((van) => {
      return {
        vehicleTypeValue: van.parentValue,
        vehicleSubTypeValues: van.subValues?.map((subValue) => subValue.value) || []
      };
    });
  }

  setPrimaryParam() {
    this.primaryParam = this.$route.path.split('/')[1];
  }

  @Watch('$route', { deep: true })
  onUrlChange() {
    if (this.$route.name !== VanSelectionRoute) {
      store.dispatch('setSelectedCar', null);
    }
    this.checkForCity();
    this.checkForCarType();
  }

  checkForCarType() {
    if (this.$route.query.vozidla) {
      const vans = (this.$route.query.vozidla as string).split(',');
      const clonedVanTypes = cloneDeep(this.allVanTypes);
      // find van categories that have a parent or sub-value in the URL Query
      const filteredVans = clonedVanTypes.filter(
        (vanType) =>
          vans.includes(vanType.parentQuerySlug || '') ||
          vanType.subValues?.some((subValue) => vans.includes(subValue.slug))
      );
      // filter out subvalues that are not in the query
      filteredVans.forEach((van) => {
        van.subValues = van.subValues?.filter((subValue) => vans.includes(subValue.slug));
      });
      this.filteredVans = filteredVans;
      this.getSpecificCars();
    } else if (store.state.eligiblePrimaryParams.includes(this.primaryParam)) {
      // find car type that corresponds to the URL param
      const filteredCategory = this.allVanTypes.find((vanType) => vanType.parentPathSlug === this.primaryParam);
      if (filteredCategory) {
        this.filteredVans = [filteredCategory];
      }
      this.getSpecificCars();
    }
  }

  private checkForCity() {
    this.setPrimaryParam();
    const secondaryParam = this.$route.params.secondaryParam;
    if (this.$route.query.parkoviste && this.$route.query.parkoviste.length) {
      const parkings = (this.$route.query.parkoviste as string)?.split(',');
      const clonedParkings = cloneDeep(this.allLocationFilters);
      // find and select subvalues of the URL Query from all parkings
      clonedParkings.forEach((city) => {
        city.subValues = city.subValues?.filter((subValue) => parkings.includes(subValue.slug));
      });
      // do not select parkings that don't have any subvalues selected
      const filteredParkings = clonedParkings.filter((parking) => parking.subValues && parking.subValues.length > 0);
      this.filteredLocations = filteredParkings;
    } else if (
      store.state.eligiblePrimaryParams.includes(this.primaryParam) ||
      store.state.eligiblePrimaryParams.includes(secondaryParam)
    ) {
      // if there is a parking location that corresponds to the url city, set it as the only selected location
      const foundParking = this.allLocationFilters.find((parking) => parking.parentPathSlug === secondaryParam);
      if (foundParking) {
        this.filteredLocations = [foundParking];
      }
    }
  }

  async mounted() {
    await this.setFilters();
    if (!store.state.eligiblePrimaryParams?.length) {
      store.subscribe((event) => {
        if (event.type === 'SET_ELIGIBLE_PRIMARY_PARAMS' && event.payload) {
          this.checkForCity();
          this.checkForCarType();
        }
      });
    } else {
      this.checkForCity();
      this.checkForCarType();
    }
    store.subscribe((event) => {
      if (event.type === 'SET_SELECTED_CAR' && event.payload) {
        const parking = (event.payload as Car).parkingLocation;
        this.focusMapTo = [parking.lat, parking.lng];
      } else if (event.type === 'SET_MOUSEOVER_CAR' && event.payload !== null) {
        this.highlightedCar = event.payload._id;
      } else if (event.type === 'SET_MOUSEOVER_CAR' && event.payload === null) {
        this.highlightedCar = null;
      }
    });
  }
  deactivated() {
    this.clearUserInputs();
  }

  private async setFilters() {
    const vehicleFilters = store.state.vehicleFilters;
    const parkingFilters = store.state.parkingFilters;
    this.allVanTypes = vehicleFilters;
    this.allLocationFilters = parkingFilters;
    this.carTypePathSlugs = vehicleFilters.map((vf) => vf.parentPathSlug);
    this.citySlugs = parkingFilters.map((pf) => pf.parentPathSlug);
  }
  private clearUserInputs() {
    this.filteredLocations = [];
    this.filteredVans = [];
    this.selectedSortOption = defaultCarSortOption;
    this.$store.dispatch('setCarQuerySlugs', null);
    this.$store.dispatch('setParkingQuerySlugs', null);
  }

  private async getSpecificCars() {
    const filter: CarFilterInput = {
      /* if there are all subTypes of a category selected, 
      only send out the parent value to 
      include vans with no subType */
      vehicleTypes: this.filteredVanTypes.map((vanType) => {
        return {
          vehicleTypeValue: vanType.vehicleTypeValue,
          vehicleSubTypeValues: this.allVanTypes.find(
            (van) =>
              van.parentValue === vanType.vehicleTypeValue &&
              van.subValues?.length === vanType.vehicleSubTypeValues?.length
          )
            ? []
            : vanType.vehicleSubTypeValues
        };
      }),
      city: '',
      parkingDescriptions: this.filteredLocations.reduce((acc, curr) => {
        if (curr.subValues) {
          acc.push(...curr.subValues.map((subValue) => subValue.value));
        }
        return acc;
      }, [] as string[])
    };
    if (this.citySlugs?.includes(this.$route.params.secondaryParam)) {
      filter.city = this.$route.params.secondaryParam;
      store.dispatch('setCityUrl', this.$route.params.secondaryParam);
    }
    const path = this.$route.path.substring(1);
    if (this.carTypePathSlugs?.includes(path)) {
      store.dispatch('setCarTypeUrl', path);
    } else if (!store.state.carTypeUrl || path === RENTAL) {
      store.dispatch('setCarTypeUrl', RENTAL);
    }

    if (!Object.values(filter).every((x) => x === null || x === '')) {
      await this.searchCars(filter);
    } else if (this.primaryParam === RENTAL) {
      await this.searchCars();
    } else {
      this.$router.push({ name: 'not-found' });
    }
  }

  public async searchCars(filters?: CarFilterInput) {
    if (!this.$route.query.from || !this.$route.query.to) {
      this.resetSearchDatesToNow();
    }
    const start = this.$route.query.from.toString();
    const end = this.$route.query.to.toString();
    this.daysCount = moment(end).diff(moment(start), 'days') + 1;

    if (!moment(start).isValid() || !moment(end).isValid() || moment(start).isAfter(end)) {
      this.$toast.error('Zvolené datum rezervace není platné');
      this.resetSearchDatesToNow();
      return;
    }

    if (
      moment(start).isBefore(moment().format(DEFAULT_DATE_FORMAT)) ||
      moment(end).isBefore(moment().format(DEFAULT_DATE_FORMAT))
    ) {
      this.$toast.error('Zvolené datum nemůže být v minulosti');
      this.resetSearchDatesToNow();
      return;
    }

    if (!this.__SSR) {
      this.$fbq('trackCustom', 'SearchVans');
    }

    this.setStartDate(start);
    this.setEndDate(end);

    this.loading = true;
    this.failed = false;
    try {
      const args = {
        start,
        end,
        filters,
        orderBy: {
          prop: this.selectedSortOption.prop,
          order: this.selectedSortOption.order
        }
      };

      const carsAvailabilityArray = await carService.searchCar(args);
      await store.dispatch('setAvailabilityCars', carsAvailabilityArray);
      this.selectedDeposits = this.carsWithAvailability.map((cwa) => {
        return { id: cwa.car._id, deposit: this.deposits[0] };
      });
      const carsAvailableArray = this.carsWithAvailability.map(
        (carAvailability: CarAvailability) => carAvailability.car
      );
      await store.dispatch('setAvailableCars', carsAvailableArray);
      if (this.$route.params.secondaryParam) {
        this.carsWithAvailability.map((cwa) => {
          if (cwa.car.urlSlug === this.$route.params.secondaryParam) {
            store.dispatch('setSelectedCar', cwa.car);
            this.highlightedCar = cwa.car._id;
          }
        });
      }
    } catch (e) {
      console.error('search vans failed', e);
      this.failed = true;
    } finally {
      this.loading = false;
    }
  }

  private resetSearchDatesToNow() {
    const defaultStartDate = moment().format(DEFAULT_DATE_FORMAT);
    const defaultEndDate = moment().format(DEFAULT_DATE_FORMAT);
    this.$router
      .push({
        query: {
          ...this.$route.query,
          from: moment(defaultStartDate).format(DEFAULT_DATE_FORMAT),
          to: moment(defaultEndDate).format(DEFAULT_DATE_FORMAT)
        }
      })
      .catch(ignoreNavigationDuplicated);
  }

  private setStartDate(date: string) {
    store.dispatch('setReservationStartDate', date);
  }

  private setEndDate(date: string) {
    store.dispatch('setReservationEndDate', date);
  }

  public closeVanDetail() {
    const params = {
      ...(store.state.cityUrl ? { secondaryParam: store.state.cityUrl } : '')
    };
    this.$router
      .push({
        params,
        name: createVanSelectionRoute(),
        query: {
          from: this.$route.query.from,
          to: this.$route.query.to,
          vozidla: store.state.carQuerySlugs || undefined,
          parkoviste: store.state.parkingQuerySlugs || undefined
        }
      })
      .catch(ignoreNavigationDuplicated);
    store.dispatch('setSelectedCar', null);
    this.highlightedCar = null;
  }

  public getCarsPositions() {
    const cars = this.$store.state.selectedCar ? [this.$store.state.selectedCar] : this.filteredCars;
    return getParkingPositionsFromCars(cars);
  }

  selectCar(carId: string) {
    const selectedCar = this.filteredCars.find((car) => car._id === carId);
    if (!selectedCar) {
      return;
    }

    this.highlightedCar = selectedCar._id;
    store.dispatch('setSelectedCar', selectedCar);
  }

  setFilteredLocations(locations: FilterOption[]) {
    this.filteredLocations = locations;
    this.getSpecificCars();
  }

  setFilteredVans(vans: FilterOption[]) {
    this.filteredVans = vans;
    this.getSpecificCars();
  }

  setSortOption(option: CarSortOption) {
    this.selectedSortOption = option;
    this.getSpecificCars();
  }

  setLoading(value: boolean) {
    this.loading = value;
  }
}
