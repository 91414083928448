






















import { Component, Prop, Mixins } from 'vue-property-decorator';
import CheckboxField from '@/components/ui/form/CheckboxField.vue';
import { ArrowUpIcon, ArrowDownIcon } from 'vue-feather-icons';
import { CarSortOption } from '@/models/carSort';
import { GtagMixin } from '@/mixins/googleAnalytics/gtag.mixin';

@Component({
  components: { CheckboxField, ArrowUpIcon, ArrowDownIcon }
})
export default class VanSort extends Mixins(GtagMixin) {
  @Prop({ required: true, type: Array }) readonly allOptions!: CarSortOption[];
  @Prop({ required: true, type: Object }) value!: CarSortOption;

  public open = false;

  public selectOption(option: CarSortOption) {
    this.open = false;
    this.$emit('input', option);
    this.triggerGtagEvent('van-listing-sorting', 'click');
  }

  private handleMouseClick(ev: MouseEvent) {
    if (this.open && !this.$el.contains(ev.target as Node | null)) {
      this.open = false;
    }
  }

  private initMouseClickHandler() {
    document.removeEventListener('click', this.handleMouseClick);
    document.addEventListener('click', this.handleMouseClick);
  }

  private destroyMouseClickHandler() {
    this.open = false;
    document.removeEventListener('click', this.handleMouseClick);
  }

  async mounted() {
    this.initMouseClickHandler();
  }

  activated() {
    this.initMouseClickHandler();
  }

  destroyed() {
    this.destroyMouseClickHandler();
  }

  deactivated() {
    this.destroyMouseClickHandler();
  }
}
