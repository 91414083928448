
























































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Hooper, Slide, Navigation as HooperNavigation, Pagination as HooperPagination } from 'hooper';
import Tooltip from '@/components/tooltip.vue';
import VanDetailParameters from '@/components/vans/vanDetailParameters.vue';
import VanReservationInfo from '@/components/vans/vanReservationInfo.vue';
import { AllowedZones, ALLOWED_ZONE_KEY, Car, CarImage, CarParameter } from '@/store/types';
import { MapPinIcon, XIcon } from 'vue-feather-icons';
import {
  filterMainParameters,
  filterNotMainParameters,
  getParameterIconSrc
} from '@/components/vans/utils/carParameters';
import store from '@/store';
import moment from 'moment';
import { CarsDepositInfo } from '@/models/carsDeposit';
import { createFullVanName } from '@/components/vans/utils/createFullVanName';
import Loading from '@/components/ui/Loading.vue';
import { ReservationRoute } from '@/router/routes';

import 'hooper/dist/hooper.css';

@Component({
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    VanDetailParameters,
    VanReservationInfo,
    Tooltip,
    MapPinIcon,
    XIcon,
    Loading
  }
})
export default class VanDetail extends Vue {
  @Prop() readonly car!: Car;
  @Prop() readonly daysCount!: number;
  @Prop() readonly isAvailable!: boolean;
  @Prop() readonly deposits!: CarsDepositInfo[];
  @Prop() readonly selectedDeposit!: CarsDepositInfo;
  @Prop() readonly onSelectedDepositChange!: (deposit: CarsDepositInfo) => void;

  public $refs!: {
    sliderDetail: HopperSliderElement;
  };

  private galleryOpened = false;
  private loading = true;
  private width = 0;
  private currentSlideDetail = 0;

  closeVanDetail() {
    this.galleryOpened = false;
    this.$emit('closeDetail');
  }

  get fullVanName() {
    return createFullVanName(this.car);
  }

  get mainParameters(): CarParameter[] {
    // TODO - hardcoded - get dynamic data from BE
    const baseText = 'neomezeně v rámci ČR';
    const allowedZone: CarParameter = {
      key: ALLOWED_ZONE_KEY,
      value: this.car.allowedZone === AllowedZones.Europe ? `${baseText}; po Evropě dle domluvy` : baseText
    };
    return filterMainParameters([...this.car.parameters, allowedZone]);
  }

  get notMainParameters(): CarParameter[] {
    return filterNotMainParameters(this.car.parameters);
  }

  private parameterIconSrc(parameter: CarParameter) {
    return getParameterIconSrc(parameter.key, '#ffc125', 24, 24);
  }

  get googleMapsLink() {
    return `https://www.google.com/maps/search/${this.car.parkingLocation.lat},+${this.car.parkingLocation.lng}`;
  }

  get parkingName() {
    return this.car.locationDescription;
  }

  // TODO - hardcoded - get dynamic data from BE
  get parkingAddress() {
    return 'Ulice, PSČ Praha X - Část';
  }

  get startDate() {
    return store.state.reservationStartDate;
  }

  get startDayMonth() {
    return moment(this.startDate).locale('cs').format('D. MMMM');
  }

  get endDate() {
    return store.state.reservationEndDate;
  }

  get endDayMonth() {
    return moment(this.endDate).locale('cs').format('D. MMMM');
  }

  private makeReservation() {
    const start = this.$route.query.from;
    const end = this.$route.query.to;
    if (start && end) {
      store.dispatch('setSelectedCar', this.car);
      store.dispatch('setSearchParameters', {
        start,
        end
      });
      this.$router.push({
        name: ReservationRoute,
        query: { from: start, to: end, car: this.car._id }
      });
    }
  }

  private openImageGallery() {
    this.galleryOpened = true;
  }

  private closeImageGallery() {
    this.galleryOpened = false;
  }

  private onSlideDetail(payload: HopperOnSlidePayload) {
    this.currentSlideDetail = payload.currentSlide;
  }

  private onSlideGallery(payload: HopperOnSlidePayload) {
    this.$refs.sliderDetail.slideTo(payload.currentSlide);
  }

  mounted() {
    window.addEventListener('resize', () => {
      this.width = window.innerWidth;
    });
    window.scrollTo(0, 0);
    this.loadFirstImage();
  }

  @Watch('car')
  resetGallery() {
    this.$refs.sliderDetail.slideTo(0);
  }

  @Watch('car')
  async loadFirstImage() {
    this.loading = true;
    await this.constructImagePromise(this.car.images[0]);
    this.loading = false;
  }

  constructImagePromise(imageSrc: CarImage): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const img = new Image();
      if (this.width < 640) {
        img.src = imageSrc.small;
      } else {
        img.src = imageSrc.large;
      }
      img.onload = () => {
        resolve();
      };
      img.onerror = reject;
    });
  }
}
