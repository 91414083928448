







































































import { Component, Prop, Vue } from 'vue-property-decorator';
import store from '../../store';
import { Car, CarAvailability, FilterOption } from '@/store/types';
import VanFilter from '@/components/vans/vanFilter.vue';
import VanCardNew from '@/components/vans/vanCardNew.vue';
import VanReservationDateTooltip from '@/components/vans/vanReservationDateTooltip.vue';
import Loading from '@/components/ui/Loading.vue';
import Button from '@/components/ui/Button.vue';
import { RefreshCcwIcon } from 'vue-feather-icons';
import { City } from '@/models/city';
import ClientOnly from 'vue-client-only';
import { RENTAL } from '@/models/carType';
import { createVanSelectionRoute } from '@/utils/routing';
import { CarSortOption } from '@/models/carSort';

@Component({
  components: {
    Loading,
    VanFilter,
    VanCardNew,
    VanReservationDates: () => import('@/components/vans/vanReservationDates.vue'),
    Button,
    RefreshCcwIcon,
    VanReservationDateTooltip,
    ClientOnly
  }
})
export default class VanSelection extends Vue {
  @Prop({ default: null }) readonly city!: City | null;
  @Prop({ required: true, type: Array }) citySlugs!: string[];
  @Prop({ required: true, type: Boolean }) loading!: boolean;
  @Prop({ required: true, type: Array }) filteredCarsWithAvailability!: CarAvailability[];
  @Prop({ required: true, type: Array }) carTypeSlugsPlurals!: string[] | null;
  @Prop({ required: true, type: Array }) filteredLocations!: FilterOption[];
  @Prop({ required: true, type: Array }) filteredVans!: FilterOption[];
  @Prop({ required: true, type: Boolean }) failed!: boolean;
  @Prop({ required: true, type: Array }) allVans!: FilterOption[];
  @Prop({ required: true, type: Array }) allLocations!: FilterOption[];
  @Prop({ required: true, type: Object }) selectedSortOption!: CarSortOption;
  @Prop({ required: true, type: Array }) allSortOptions!: CarSortOption[];
  @Prop() readonly availableVansCount!: number;
  @Prop() readonly daysCount!: number;
  @Prop() readonly onSortOptionChange!: (option: CarSortOption) => void;

  get RENTAL() {
    return RENTAL;
  }

  get carTypeUrl() {
    return store.state.carTypeUrl;
  }

  get cityUrl() {
    return store.state.cityUrl;
  }

  get carsWithAvailability() {
    return store.state.carsWithAvailability;
  }

  navigateToCar(car: Car) {
    this.$router.push({
      name: createVanSelectionRoute(),
      params: { secondaryParam: car.urlSlug },
      query: { from: this.$route.query.from, to: this.$route.query.to }
    });
  }

  private toggleGroupFilter(carTypeValue?: string, cityValue?: string) {
    if (cityValue !== undefined) {
      if (this.citySlugs.includes(cityValue) || cityValue === '') {
        store.dispatch('setCityUrl', cityValue);
        delete this.$route.query.parkoviste;
      }
    }
    if (carTypeValue !== undefined) {
      if ([...(this.carTypeSlugsPlurals || []), RENTAL].includes(carTypeValue)) {
        store.dispatch('setCarTypeUrl', carTypeValue);
        if (carTypeValue !== RENTAL) {
          // if there is a new main car type in the url path, remove the query params
          delete this.$route.query.vozidla;
        }
      }
    }
    const params = {
      ...(store.state.cityUrl ? { secondaryParam: store.state.cityUrl } : '')
    };
    this.$router.push({
      params,
      name: createVanSelectionRoute(),
      query: this.$route.query
    });
  }

  private setQuery(carSlugs?: string[], citySlugs?: string[]) {
    let vans;
    if (carSlugs) {
      vans = carSlugs.join(',');
      store.dispatch('setCarQuerySlugs', vans);
    } else {
      vans = this.$route.query.vozidla;
    }
    let parkings;
    if (citySlugs) {
      parkings = citySlugs.join(',');
      store.dispatch('setParkingQuerySlugs', parkings);
    } else {
      parkings = store.state.parkingQuerySlugs;
    }

    this.$router.push({
      name: this.$route.name || undefined,
      params: this.$route.params,
      query: {
        from: this.$route.query.from,
        to: this.$route.query.to,
        parkoviste: parkings || undefined,
        vozidla: vans || undefined
      }
    });
  }

  searchCars() {
    this.$emit('searchCars');
  }

  public handleFilteredLocationsChange(newlySelectedLocations: FilterOption[]) {
    // if there is only one selected location and its subvalues are all selected, set the url to its city slug
    if (
      newlySelectedLocations.length === 1 &&
      this.allLocations.find(
        (location) =>
          location.parentValue === newlySelectedLocations[0].parentValue &&
          location.subValues?.length === newlySelectedLocations[0].subValues?.length
      )
    ) {
      const slug = newlySelectedLocations[0].parentPathSlug;
      this.toggleGroupFilter(undefined, slug ?? '');
    } else {
      this.toggleGroupFilter(undefined, '');
      if (
        this.allLocations.every((location) => {
          const selectedCity = newlySelectedLocations.find((city) => city.parentValue === location.parentValue);
          return selectedCity && selectedCity.subValues?.length === location.subValues?.length;
        })
      ) {
        this.setQuery(undefined, []);
      } else {
        this.setQuery(undefined, this.getFilterOptionsSlugs(newlySelectedLocations));
      }
    }
    this.$emit('loading', true);
    this.$emit('filteredLocations', newlySelectedLocations);
  }

  public handleFilteredVansChange(newlySelectedVans: FilterOption[]) {
    // if there is only one selected van type, set the url to its name in plural
    if (
      newlySelectedVans.length === 1 &&
      this.allVans.find(
        (van) =>
          van.parentValue === newlySelectedVans[0].parentValue &&
          van.subValues?.length === newlySelectedVans[0].subValues?.length
      )
    ) {
      const slugPlural = newlySelectedVans[0].parentPathSlug;
      if (slugPlural) {
        this.toggleGroupFilter(slugPlural);
      }
    } else {
      // there are multiple selected van types or not all subvalues are selected
      this.toggleGroupFilter(RENTAL);
      if (
        this.allVans.every((van) => {
          const selectedVan = newlySelectedVans.find((newVan) => newVan.parentValue === van.parentValue);
          return selectedVan && selectedVan.subValues?.length === van.subValues?.length;
        })
      ) {
        this.setQuery([]);
      } else {
        this.setQuery(this.getFilterOptionsSlugs(newlySelectedVans));
      }
    }
    this.$emit('loading', true);
    this.$emit('filteredVans', newlySelectedVans);
  }
  private getFilterOptionsSlugs(options: FilterOption[]) {
    // return all selected subvalues slugs or a parentSlug, when there are no subvalues
    return options
      .map((option) => option.subValues?.map((subValue) => subValue.slug) || [option.parentQuerySlug || ''])
      .flat();
  }
}
